@import '../../../../styles/customMediaQueries.css';

.root {
  &:target {
    scroll-margin-top: calc(var(--topbarHeight) + 24px);

    @media (--viewportMedium) {
      scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
    }
  }
}
